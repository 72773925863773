.page {
  font-family: $general_font;

  > .front_top {

    &.gray {
      background-color:$gray_background;
    }
    > .general_header {

      .row > div {
        height:100px;

        &.logotype {
          background-color:$blue_general;
          line-height:100px;
        }

        &.lang {
          line-height:100px;

          .lang_list {
            display:inline-block;
            position:relative;
            line-height:100%;

            button {
              border:0;
              color: $blue_general;
              font-weight:700;
              font-size:15px;
              text-transform: uppercase;
              background:none!important;
            }
          }
        }

        &.menu {
          .nav {
            padding:0;
            margin:0;
            white-space:nowrap;
            display:inline-block;
            vertical-align: top;

            > li {
              display:inline-block;

               a {
                display:block;
                line-height:100px;
                 font-size:15px;
                 color: $blue_general;
                 font-weight:700;
                 text-transform: uppercase;
                 padding: 0 15px;

                 &:hover {
                   text-decoration:none;
                 }
              }
            }
          }

          .hamburger {
            line-height:110px;
            width: 100px;
            height: 100px;
            display: inline-block;
            vertical-align: top;
            text-align:center;

            > div {
              width: 30px;
              display:inline-block;

              > div {
                width: 30px;
                height: 3px;
                background-color: $blue_general;
                margin-bottom: 5px;

                &:last-child {
                  margin-bottom:0;
                }
              }
            }
          }
        }
      }
    }
  }
}